import './bootstrap';
import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

/*
 |--------------------------------------------------------------------------
 | Custom Support
 |--------------------------------------------------------------------------
 |
 | Here we'll set up the custom components, plugins, and section specific
 | javascript files that are going to be needed to run our application
 | this is a nice place to require all sorts of custom jQuery code.
 |
 */

require('./support/Collapse');
require('./support/Tab');

/*
 |--------------------------------------------------------------------------
 | Custom Elements
 |--------------------------------------------------------------------------
 |
 | Here we'll set up the custom components, plugins, and section specific
 | javascript files that are going to be needed to run our application
 | this is a nice place to require all sorts of custom jQuery code.
 |
 */

require('./html/image');
require('./html/menu');
require('./html/sliders');
require('./html/share');
