/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */

 $(function () {
    'use strict';

    // Display menu mobile
    $('.menu-open').on('click', e => {
        e.preventDefault();

        $('#menu').addClass('active');
        $('html, body').addClass('overflow-hidden');
    });

    // Hide menu mobile
    $('.menu-close').on('click', e => {
        e.preventDefault();

        $('#menu').removeClass('active');
        $('html, body').removeClass('overflow-hidden');
    });
 });
