/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(function () {
    'use strict';

    /*
     | Script to make collapse work on the site.
     */

    // Add class 'in' for show collapse content.
    $('a[data-toggle="collapse-open"]').click((e) => {
        e.preventDefault();
        let collapseItem = $(e.currentTarget).attr('data-target');

        if ($(e.currentTarget).hasClass('show')) {
            $(e.currentTarget).toggleClass('show');
            $('#' + collapseItem).slideUp();
        } else {
            $(e.currentTarget)
                .closest('.collapse-container')
                .find('a[data-toggle="collapse-open"]')
                .removeClass('show');
            $(e.currentTarget).toggleClass('show');
        }

        if ($('#' + collapseItem).hasClass('in')) {
            $('#' + collapseItem).toggleClass('in');
        } else {
            $(e.currentTarget)
                .closest('.collapse-container')
                .find('.collapse')
                .each((i, t) => {
                    $(t).removeClass('in').slideUp();
                });

            $('#' + collapseItem)
                .toggleClass('in')
                .slideToggle();
        }
    });
});
